import React from 'react';

import logoSymbol from 'assets/img/symbol.png';

import { Container } from './styles';

import './styles.css';

const FallbackSpinner: React.FC = () => {
  return (
    <Container>
      <img className="fallback-logo" src={logoSymbol} alt="Cursology" />
      <div className="loader" />
    </Container>
  );
};

export default FallbackSpinner;
